import react from "react";
import { useCallback, useRef, useState, useEffect } from "react";
import axios from "axios";

// Code does not show last rows in load more, not really problematic when we have a lot of rows which will be the case in our app , debugging not done yet

var pagecount = 1;
let url = process.env.REACT_APP_SERVER_API_ENDPOINT;
let limit = 4;
function getjobs({ search_query, pagecount, limit }) {
  console.log(
    url +
      "searchjobpage" +
      "?search_query=" +
      search_query +
      "&limit=" +
      limit +
      "&page=" +
      pagecount
  );
  return axios.get(
    url +
      "searchjobpage" +
      "?search_query=" +
      search_query +
      "&limit=" +
      limit +
      "&page=" +
      pagecount
  );
}

export function useInfiniteLoadingSearch(setIsLoading) {
  const initialPageLoaded = useRef(false);
  const [hasMores, setHasMore] = useState(true);
  const [jobsisearch, setjobsisearch] = useState([]);
  {
    /* let search_count = 0; */
  }

  const loadjobsearchinit = async (search_query) => {
    setIsLoading(true);

    // console.log("loadjobsearchinit");
    if (search_query) {
      let data = await getjobs({
        search_query,
        pagecount,
        limit,
      }).then((res) => {
        // console.log(pagecount);
        if (initialPageLoaded.current == true) {
          {
            /* pagecount++; */
          }
        }
        {/* console.log(res.data); */}
        if (res.data == []) {
          setHasMore(false);
        }
        return res.data;
      });

      {/* console.log(data[0]); */}
      if (data[0] !== undefined) {
        {/* console.log("yo"); */}
        {/* console.log(data[0].search_count); */}
        if (data[0].search_count != "undefined") {
          {/* console.log(data); */}
          setHasMore(data[0].search_count / limit > pagecount);
          setjobsisearch([...data]);
        }
      }
    } else {
      pagecount = 1;
    }
    setIsLoading(false);

  };

  const loadjobsearchmore = async (search_query) => {
    // console.log("loadjobsearchmore");
    if (search_query) {
      if (initialPageLoaded.current == true) {
        pagecount++;
      }
      let data = await getjobs({
        search_query,
        pagecount,
        limit,
      }).then((res) => {
        {/* console.log(pagecount); */}
        {/* console.log(res.data); */}
        return res.data;
      });

      if (data[0] !== undefined) {
        {/* console.log(data[0].search_count); */}
        if (data[0].search_count != "undefined") {
          {/* console.log(data); */}
          setHasMore(data[0].search_count / limit > pagecount);
          setjobsisearch((prevjobs) => [...prevjobs, ...data]);
        }
      }
    } else {
      pagecount = 1;
    }
  };

  useEffect(() => {
    if (initialPageLoaded.current) {
      return;
    }
    pagecount = 1;
    loadjobsearchinit();

    setTimeout(function () {
      initialPageLoaded.current = true;
    }, 1500);
  }, [getjobs]);

  return { jobsisearch, hasMores, loadjobsearchinit, loadjobsearchmore };
}
