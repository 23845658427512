import { useForm,  Controller, SubmitHandler } from "react-hook-form";
import ReactDOM from "react-dom";
import React, { useState } from "react";
import Input from "@material-ui/core/Input";
import Modal from '@mui/material/Modal';
import TextField from '@material-ui/core/TextField';
import { styled } from '@mui/material/styles';
import Button from '@material-ui/core/Button';
import Box from '@mui/material/Box';
import axios from 'axios';
import MenuItem from '@mui/material/MenuItem';
import ResponsiveAppBar from "../components/ResponsiveAppBar";
import { useTheme, ThemeProvider, createTheme } from '@mui/material/styles';
import { useNavigate } from "react-router-dom";

import { useSendTransaction, usePrepareSendTransaction } from 'wagmi';
import { useWaitForTransaction } from 'wagmi';
import { WagmiConfig, createClient,
  configureChains,
  defaultChains,
  chain } from 'wagmi';
import { BigNumber } from "ethers";
import { publicProvider } from "wagmi/providers/public";

import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';


{/* import { makeStyles } from '@material-ui/core'; */}
const url = process.env.REACT_APP_SERVER_API_ENDPOINT

const darkTheme = createTheme({
  spacing:4,
  palette: {
    mode: "dark",
    primary: {
        light:"#65e7a2",
        main: "#65e7a2",
        dark: "#65e7a2",
      // contrastText: will be calculated to contrast with palette.primary.main
  },
  secondary: {
        light:"#65e7a2",
        main: "#65e7a2",
        dark: "#65e7a2",
    },
  },
});

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 400,
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  overflow: 'auto', 
  pt: 2,
  px: 4,
  pb: 3,
};


const CssTextField = styled(TextField)({

  fontFamily: 'Cyber',

  '& label': {
    fontFamily: 'Cyber',
  },
  '& label.Mui-focused': {
    fontFamily: 'Cyber',
    color: '#65e7a2',
  },
  '& .MuiInput-underline:after': {
    fontFamily: 'Cyber',
    borderBottomColor: '#65e7a2',
  },
  '& .MuiOutlinedInput-root': {
    fontFamily: 'Cyber',
    '& fieldset': {
      borderColor: 'rgb(64,63,62)',
    },
    '&:hover fieldset': {
      borderColor: 'rgb(64,65,65)',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#65e7a2',
    },
  },
  '& .MuiInputBase-input': {
    fontFamily: 'Cyber',
    color: 'rgb(224,224,224)',
  }
});


const currencies = [
  {
    value: 'BTC',
    label: '฿',
  },
  {
    value: 'ETH',
    label: 'Ξ',
  },
  {
    value: 'Tether',
    label: 'USDT',
  },
  {
    value: 'USD Coin',
    label: 'USDC',
  },
  {
    value: 'Binance Coin (BNB)',
    label: 'BNB ',
  },
  {
    value: 'Cardano',
    label: 'ADA',
  },
  {
    value: 'XRP',
    label: 'XRP',
  },
  {
    value: 'Solana',
    label: 'SOL',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];

enum GenderEnum {
  ethereum = "ethereum",
  bitcoin = "bitcoin",
  other = "other",
}


 const validationSchema = Yup.object().shape({
    name: Yup.string().required('Title is required').min(5, 'Title must be at least 5 characters').max(200, 'Title must not exceed 200 characters'),
    description: Yup.string()
      .required('Description is required')
      .min(6, 'Description must be at least 6 characters')
      .max(100000, 'Description must not exceed 100000 characters'),
    joblocation: Yup.string()
      .required('Job Location is required')
      .max(200, 'Job Location must not exceed 100000 characters'),
    salary: Yup.number().required('Salary is required').positive().integer(),
    salarycurrency: Yup.string().required('salarycurrency is required'),
    hiringorganization: Yup.string()
      .required('Hiring Organization is required')
      .max(200, 'Hiring Organization must not exceed 200 characters'),
    applicationcontact: Yup.string()
      .required('Email is required').email(),
    url: Yup.string()
      .url().nullable(),
  });



{/* interface IFormInput { */}
{/*   name: String; */}
{/*   description: String; */}
{/*   salary: Number; */}
{/*   hiringorganization: String; */}
{/*   applicationcontact: String; */}
{/*   joblocation: String; */}
{/*   title: String; */}
{/*   salarycurrency: GenderEnum; */}
{/*   url: String; */}
{/* } */}


function MBox(props) {

  const [TxSuccess, setTxSuccess] = useState(false);
  const [TxError, setTxError] = useState(false);
  const [errormsg, setErrormsg] = useState("");


  const { config , error } = usePrepareSendTransaction({
    request: {
      to: '0x373c2CfA6b63a375Af5292b03DB465557cb3A0B9',
      value: BigNumber.from("1000000000000000") ,
    },
  });

  const { data, isLoading, isSuccess, sendTransaction } = useSendTransaction({
    ...config,
    onSuccess(data) {
      console.log('Success', data)
      setTxSuccess(true);
      props.handleTxSuccess();
    },
    onError(error) {
      setTxError(true);
      // console.log('Error', error)
      // console.log('Error msg', error.message)
      if(error.message.slice(0,9)==="Connector"){
        setErrormsg(" Please Connect Your Wallet !!");
      }else{
        setErrormsg(error.message);  
    }},
  });

  return (

          <Box sx={{ ...style, width: 400 }}>
           <h2 style={{"margin-bottom":"10px"}} id="parent-modal-title">Submit this job for 0.001 eth</h2>
              <p id="parent-modal-description">
                  <button disabled={!sendTransaction} onClick={() => sendTransaction?.()}>
                    Send Transaction
                  </button>
                  <div>
                {TxError && <div style={{"margin":"10px 0px 10px 0px", "word-wrap": "break-word"}} > {errormsg} </div> }
                {isLoading && <div style={{"margin":"10px 0px 10px 0px"}}>Check Wallet</div>}
                {isSuccess && <div>Success Transaction Hash: {JSON.stringify(data)}</div>}
              </div>
               </p> 
            </Box>
  );

}






export default function Postajob(props) {
  const navigate = useNavigate();
  const [salarycurrency, setCurrency] = React.useState('');
  //const [showpaymodal, setShowpaymentmodal] = React.useState(false);



  const [Dataall,setData]=useState({
      name:'',
      description:'',
      joblocation:'',
      salary:'',
      salarycurrency:'',
      applicationcontact:'',
      hiringorganisation:'',
      url:'',
  })




  const [open, setOpen] = React.useState(false);
  // const [submit, setsubmit] = useState("Submit");
  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCurrency(event.target.value);
  };
  const {
    register,
    handleSubmit,
    control,
    watch,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema)
  });

  const handleTxSuccess = () => {
    //Submit data  
    // console.log("handletxsucces");
    // console.log({Dataall});
    axios.post(url + 'jobtodb', { Dataall })
      .then(res => {
        // console.log(res);
        // console.log(res.data);
        // go to main menu page : routing 
        if(res.statusText == 'OK'){
          navigate("/");}
          else{
          alert("Something went wrong!!");

          }}).catch((err) => console.log(err))


  };

  const handleClose = () => { 
    //   setShowpaymentmodal(false);
    setOpen(false);
  } 

  const onSubmit: SubmitHandler<IFormInput> = (data) =>{ 

    // Payment Logic 
    setOpen(true);
    // Payment Logic end 
    
    // console.log(window.localStorage.getItem("id"));
    let id = window.localStorage.getItem("id");
    // console.log("formdata", data);
    //state here is for saving the state for the submission after transaction is Successful 
    //


    setData({
        name:data.name,
        description:data.description,
        joblocation:data.joblocation,
        salary:data.salary,
        salarycurrency:data.salarycurrency,
        applicationcontact:data.applicationcontact,
        hiringorganisation:data.hiringorganization,
        url:data.url,
    })



    //
    data["id"] = id;
    let paymentconfirmation = false;
    if(!paymentconfirmation){
    // you can allow users to post freely at 0 price  
    axios.post(url + 'jobtodb', { data })
      .then(res => {
        // console.log(res);
        // console.log(res.data);
        // go to main menu page : routing 
        if(res.statusText == 'OK'){
          navigate("/");}
          else{
          alert("Something went wrong!!");

          }
      })}else{
        
      }
  }
  return (

    <ThemeProvider theme={darkTheme}>
    <React.Fragment>

      <ResponsiveAppBar client={props.client}/>
      <main style={{ padding: "1rem 0" }}>
        <h2 id="postjob">Post a job</h2>
      </main>
      <form id="jobform" sx={{ 'fontFamily':'Cyber' , 'margin-bottom':'20px'}} onSubmit={handleSubmit(onSubmit)}>
        <div id="divform">
        <Controller
        name="name"
        control={control}
        defaultValue=""
        render={({ field: { onChange, value }, fieldState: { error, errors } }) => (
          <CssTextField id="custom-css-outlined-input" 
            sx={{
        width: "100%", 
            "font-family":"Cyber"
      }}
            label="Title"
            autoFocus="true"
            value={value}
            color="primary"
            onChange={onChange}
            error={!!error}
            // inputProps={{...register("test", {
              //    minLength : {
                //      value: 3,
                //     message: 'minLength need to be more than 3' // JS only: <p>error message</p> TS only support string
              //    }
              //})}}
              helperText={error ? error.message : null}
          />
        )}
        rules={{ required: 'Job title required' }}
      />
        </div>
        <div id="divform">
        <Controller
        name="description"
        control={control}
        defaultValue=""
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <CssTextField id="custom-css-outlined-input" 
        sx={{
    width: "100%",
  }}
            label="Description"
            multiline
            color="primary"
            value={value}
            onChange={onChange}
            rows={10}
            error={!!error}
            helperText={error ? error.message : null}
          />
        )}
        rules={{ required: 'Description is required' }}
      />
        </div>
        <div id="divform">
        <Controller
        name="joblocation"
        control={control}
        defaultValue=""
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <CssTextField id="custom-css-outlined-input"
        sx={{
    width: "100%",
  }}
            label="Job Location"
            color="primary"
            value={value}
            onChange={onChange}
            error={!!error}
            helperText={error ? error.message : null}
          />
        )}
        rules={{ required: 'Job title required' }}
      />
        </div>
        <div id="divform">
        <Controller
        name="salary"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <CssTextField id="custom-css-outlined-input"
            sx={{
        width: "80%",
      }}
            label="Salary"
            color="primary"
            value={value}
            onChange={onChange}
            error={!!error}
            helperText={error ? error.message : null}
          />
        )}
        rules={{ required: 'Salary is required' }}
      />
        <Controller
        name="salarycurrency"
        control={control}
        render={({ field: { onChange, value }, fieldState: { error } }) => (
        <CssTextField id="custom-css-outlined-input"
          sx={{
            width: "19%",
          }}
              id="outlined-select-currency"
              select
              label="Select"
              onChange={onChange}
              inputProps={{
                          inputRef: (ref: any) => {
                            if (!ref) return;
                            // console.log(ref.value);
                            register(ref.value);
                          },
                        }}
              helperText="Please select your currency"
            >
              {currencies.map((option) => (
                <MenuItem sx={{"border-radius":"0px"}} key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </CssTextField>
        )}
        rules={{ required: 'Currency is required' }}
      />
        </div>
        <div id="divform">
        <Controller
        name="applicationcontact"
        control={control}
        defaultValue=""
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <CssTextField id="custom-css-outlined-input"
        sx={{
    width: "100%",
  }}
            label="Contact e-mail"
            value={value}
            color="primary"
            onChange={onChange}
            error={!!error}
            helperText={error ? error.message : null}
          />
        )}
        rules={{ required: 'Contact e-mail is required' }}
      />
        </div>
        <div id="divform">
        <Controller
        name="hiringorganization"
        control={control}
        defaultValue=""
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <CssTextField id="custom-css-outlined-input"
        sx={{
    width: "100%",
  }}
            label="Hiring Organization"
            value={value}
            color="primary"
            onChange={onChange}
            error={!!error}
            helperText={error ? error.message : null}
          />
        )}
        rules={{ required: 'Hiring Organization is required' }}
      />
        {/* <label>Organization</label> */}
        {/* <input {...register("hiringorganization", { required: true })} /> */}
        {/* {errors.hiringorganization && "Hiring organization is required"} */}
        </div>

        <div id="divform">
        <Controller
        name="url"
        control={control}
        defaultValue=""
        render={({ field: { onChange, value }, fieldState: { error } }) => (
          <CssTextField id="custom-css-outlined-input"
        sx={{
    width: "100%",
  }}
            label="URL"
            value={value}
            color="primary"
            onChange={onChange}
            error={!!error}
            helperText={error ? error.message : null}
          />
        )}
        // rules={{ required: 'URL is required' }} 
      />
        </div>
        <div>
        <Button  sx={{ 'fontFamily': 'Cyber', top: "15px", left: "20px", 'padding-bottom':'10px', 'margin-bottom': '10px', "border-radius":"0px"  }}  id="submitbut" type="submit" variant="contained">Submit</Button>
        <Modal open={open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description" >
        <>
              <WagmiConfig client={props.client}>
                <MBox handleTxSuccess={handleTxSuccess}></MBox>
              </WagmiConfig>
          </></Modal> 
        </div>
      </form>

      </React.Fragment>
    </ThemeProvider>

  );
}
