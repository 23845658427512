import { useForm,  Controller, SubmitHandler } from "react-hook-form";
import { useState, useEffect } from "react";
import ReactDOM from "react-dom";
import React from "react";
import Input from "@material-ui/core/Input";
import TextField from '@material-ui/core/TextField';
import { styled } from '@mui/material/styles';
import Button from '@material-ui/core/Button';
import axios from 'axios';
import MenuItem from '@mui/material/MenuItem';
import ResponsiveAppBar from "../components/ResponsiveAppBar";
import { useTheme, ThemeProvider, createTheme } from '@mui/material/styles';
import { useParams } from "react-router-dom";

const backendurl = process.env.REACT_APP_SERVER_API_ENDPOINT

const darkTheme = createTheme({
  spacing:4,
  palette: {
    mode: "dark",
    primary: {
        light:"#65e7a2",
        main: "#65e7a2",
        dark: "#65e7a2",
      // contrastText: will be calculated to contrast with palette.primary.main
  },
  secondary: {
        light:"#65e7a2",
        main: "#65e7a2",
        dark: "#65e7a2",
    },
  }
});

const CssTextField = styled(TextField)({
  fontFamily: 'Cyber',

  '& label': {
    fontFamily: 'Cyber',
    color: 'gray',
  },
  '& label.Mui-focused': {
    color: '#65e7a2',
  },
  '& .MuiInput-underline:after': {
    borderBottomColor: '#65e7a2',
  },
  '& .MuiOutlinedInput-root': {
    '& fieldset': {
      borderColor: 'rgb(64,63,62)',
    },
    '&:hover fieldset': {
      borderColor: 'rgb(64,65,65)',
    },
    '&.Mui-focused fieldset': {
      borderColor: '#65e7a2',
    },
  },
  '& .MuiInputBase-input': {
    fontFamily: 'Cyber',
    color: 'rgb(224,224,224)',
  }
});


const currencies = [
  {
    value: 'BTC',
    label: '฿',
  },
  {
    value: 'ETH',
    label: 'Ξ',
  },
  {
    value: 'Tether',
    label: 'USDT',
  },
  {
    value: 'USD Coin',
    label: 'USDC',
  },
  {
    value: 'Binance Coin (BNB)',
    label: 'BNB ',
  },
  {
    value: 'Cardano',
    label: 'ADA',
  },
  {
    value: 'XRP',
    label: 'XRP',
  },
  {
    value: 'Solana',
    label: 'SOL',
  },
  {
    value: 'Other',
    label: 'Other',
  },
];

enum GenderEnum {
  ethereum = "ethereum",
  bitcoin = "bitcoin",
  other = "other",
}

interface IFormInput {
  name: String;
  description: String;
  salary: Number;
  hiringorganization: String;
  applicationcontact: String;
  joblocation: String;
  title: String;
  salarycurrency: GenderEnum;
}

{/* let jobinit; */}


export default function Editajob(props) {
  const [salarycurrency, setCurrency] = useState('EUR');
  const [jobinit, setjobinit] = useState(undefined);
  const [job, setjob] = useState(undefined);
  const [titlevalue, setTitleValue] = useState('Title');
  const [descriptionvalue, setDescriptionValue] = useState('description');
  const [locationvalue, setLocationValue] = useState('location');
  const [salaryvalue, setSalaryValue] = useState('salary');
  const [emailvalue, setEmailValue] = useState('Email');
  const [urlvalue, setURLValue] = useState('URL');
  const [orgvalue, setORGValue] = useState('ORG');
  const [currencystate, setCurrencyValue] = useState('Currency');
  const [buttonstate, setButtonstate] = useState('Save Changes');

  let params = useParams();

  const { register, handleSubmit } = useForm();
  const onSubmit = (data, e) => console.log(data, e);
  const onError = (errors, e) => console.log(errors, e);




  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    // console.log(event.target.name);
    // console.log(event.target.value);
    switch (event.target.name) {
      case "title":
        // console.log("hit title");
        setTitleValue(event.target.value); 
        break;
      case "description":
        setDescriptionValue(event.target.value); 
        break;
      case "location":
        setLocationValue(event.target.value); 
        break;
      case "salary":
        setSalaryValue(event.target.value); 
        break;
      case "email":
        setEmailValue(event.target.value); 
        break;
      case "organization":
        setORGValue(event.target.value); 
        break;
      case "URL":
        setURLValue(event.target.value); 
      break;
      case "Currency":
        setCurrencyValue(event.target.value); 
      break;
    }
  };

  const saveform = () => {
    //onClick validate and when OK update values on DB 
    axios.post(backendurl + "editjob" , {name: titlevalue, description: descriptionvalue, hiringorganization:orgvalue, joblocation: locationvalue, salary: salaryvalue, id: params.jobId, url: urlvalue, applicationcontact: emailvalue}).then(
      res => {   
        if(res.status == 200){
        //show modal changes saved
        setButtonstate("Changes saved 👌");
        setTimeout(() => {setButtonstate("Save Changes")}, 5000);
        }

        console.log(res);}
    );

  }

  useEffect(() => {
    // console.log(titlevalue);
    // console.log(jobinit);
    // console.log(params.jobId);
    if (jobinit == undefined) {
      console.log("yo");
    axios
      .get(backendurl + "jobs/" + parseInt(params.jobId))
      .then((res: any) => {
        // console.log("get response");
        // console.log(res.data[0]);
        setjobinit(res.data[0]);
        {/* setjob(jobinit); */}
        // console.log('jobinit', jobinit);
        {/* console.log(job?.name); */}

      });
    }


  }, []);

useEffect(() => {
  // console.log('jobinit', jobinit);
  setTitleValue(jobinit?.name);  
  setDescriptionValue(jobinit?.description);
  setLocationValue(jobinit?.joblocation);
  setEmailValue(jobinit?.applicationcontact);
  setSalaryValue(jobinit?.salary);
  setURLValue(jobinit?.url);
  setORGValue(jobinit?.hiringorganization);
}, [jobinit])


  return ( 
  <>
  <ResponsiveAppBar client={props.client}></ResponsiveAppBar>
  <div>
    {console.log(titlevalue)}
   <form id="jobform" onSubmit={handleSubmit(onSubmit, onError)}>
   <div id="divform">
 
   <CssTextField     sx={{  width: "100%", color: "#65e7a2"}}
type="text" id="custom-css-outlined-input" label="Title" color="primary" name="title" value={titlevalue} onChange={handleChange} id="custom-css-outlined-input"
/> 

<br></br>
 </div> 
   <div id="divform">
   <CssTextField sx={{  width: "100%", }} type="text" id="custom-css-outlined-input" multiline rows={10}
 value={descriptionvalue} label="Description" name="description" onChange={handleChange}   /><br></br>
 </div> 
   <div id="divform">
   <CssTextField sx={{  width: "100%", }} type="text" id="custom-css-outlined-input" value={locationvalue} label="Location" name="location" onChange={handleChange} /><br></br>
 </div> 
   <div id="divform">
   <CssTextField sx={{  width: "80%", }} type="text" id="custom-css-outlined-input" value={salaryvalue} label="Salary"  name="salary" onChange={handleChange} />
      <CssTextField id="custom-css-outlined-input"
        sx={{
    width: "19%",
  }}
              id="outlined-select-currency"
              select
              label="Select"
              name="Currency"
              onChange={handleChange}
              inputProps={{
                          inputRef: (ref: any) => {
                            if (!ref) return;
                            console.log(ref.value);
                            register(ref.value);
                          },
                        }}
              helperText="Please select your currency"
            >
              {currencies.map((option) => (
                <MenuItem sx={{"border-radius":"0px"}} key={option.value} value={option.value}>
                  {option.label}
                </MenuItem>
              ))}
            </CssTextField>

 </div> 
   <div id="divform">
   <CssTextField sx={{  width: "100%", }} type="text" id="custom-css-outlined-input" value={emailvalue} label="Email" name="email" onChange={handleChange} /><br></br>
 </div> 
   <div id="divform">
   <CssTextField sx={{  width: "100%", }} type="text" id="custom-css-outlined-input" value={orgvalue} label="Organization" name="organization" onChange={handleChange} /><br></br>
 </div> 
   <div id="divform">
   <CssTextField sx={{  width: "100%", }} type="text" id="custom-css-outlined-input" label="URL" name="URL" value={urlvalue} onChange={handleChange}/><br></br>
 </div> 
   <Button sx={{ top: "15px", left: "20px", "margin-bottom":"20px", "border-radius":"0px" }} id="submitbut" variant="contained" type="submit" value="Submit" onClick={saveform}>{buttonstate}</Button>
   </form> 
  </div> 
  </>

         )
}
