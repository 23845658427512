import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import './Blog.css';
import ResponsiveAppBar from "../components/ResponsiveAppBar";

export default function Blog(props) {
  const [articles, setArticles] = useState([]);
  const [shnavigate, setShouldnavigate] = useState("profile");
  const [signinstate, setSignin] = useState(false);
  const [registerstate, setRegister] = useState(false);
  const client = props.client;

  const url = process.env.REACT_APP_SERVER_API_ENDPOINT;



  useEffect(() => {
    const fetchArticles = async () => {
      try {
        const response = await axios.get(`${url}articles`);
        const extractContentSnippet = (content) => {
          const match = content.match(/<p>.*?<\/p>/s)?.[0];
          if (!match) return content.slice(0, 200) + (content.length > 200 ? "..." : "");
          return match.slice(3, 200) + (match.length > 200 ? "..." : "");
        };

        const modifiedArticles = response.data.map(article => ({
          ...article,
          content: extractContentSnippet(article.content)
        }));
        setArticles(modifiedArticles);
      } catch (error) {
        console.error('Error fetching articles:', error);
      }
    };

    fetchArticles();
  }, []);

  // Function to generate a slug from a title
  const generateSlug = (title) => title.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/(^-|-$)/g, '');

  return (
    <div className="blog-container">
      <div className="blog-header">
        <div className="home-icon-container">
          <Link to="/" className="home-icon-link">
            <img src="/icons8-exit-64.png" alt="Home" className="home-icon" />
          </Link>
        </div>
        <h1 className="blog-title">Blog</h1>
        <button className="loadmorebutton">
          <Link to="/" className="findlink">Find Jobs</Link>
        </button>
      </div>
      <div className="articles-container">
        {articles.map(article => (
          <div key={article.id} className="article-item">
            <Link to={`/articles/${article.id}/${generateSlug(article.title)}`} className="article-link">
              <h2 className="article-title">{article.title}</h2>
              <p className="article-content">{article.content}</p>
            </Link>
          </div>
        ))}
      </div>
    </div>
  );
}