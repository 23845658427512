import { ConnectKitButton } from "connectkit";

const Customwalletbutton = () => {
  return (
    <>
    <ConnectKitButton.Custom>
      {({ isConnected, isConnecting, show, hide, address, ensName }) => {
      console.log({isConnected});
      return (
      <span onClick={show} >
        {isConnected ? address : "Connect Wallet "}
      </span>
      );
      }}
    </ConnectKitButton.Custom>
  </>
  );
};

export default Customwalletbutton;
