import { Link, Outlet } from "react-router-dom";
import { useState, useEffect, useCallback, useContext } from "react";
import ResponsiveAppBar from "../components/ResponsiveAppBar";
import { UserContext } from "../context/UserContext"
import { useParams } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import axios from 'axios';

const url = process.env.REACT_APP_SERVER_API_ENDPOINT;

export default function ResetPassword(props) {
  const [userContext, setUserContext] = useContext(UserContext);
  const [password, setPassword] = useState("")
  const navigate = useNavigate();

  let { token } = useParams();

  interface formDataType {[key:string]: FormDataEntryValue}

  const responseBody: formDataType = {}

  const onSubmitHandler = (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        responseBody.password = password
        console.log(responseBody)
        
        {/* let data = responseBody; */}
        //
        {/* console.log(url + 'reset-password/'+ token  , { data }); */}
        axios.patch(url + 'reset-password/'+ token  , responseBody)
        .then(res => {
        
        // go to main menu page : routing 
        if(res.statusText == 'OK'){

        //if ok popup password correctly reset and navigate to main 
          navigate("/");
        }else{
          alert("Something went wrong!!");
          }
      })
      }

  const inputChangeHandler = (setFunction: React.Dispatch<React.SetStateAction<string>>, event: React.ChangeEvent<HTMLInputElement>) => {
        setFunction(event.target.value)
    }


  return  (
  <>
   <ResponsiveAppBar client={props.client}/>
        <form onSubmit={onSubmitHandler}>
            <div><label htmlFor="password">Enter your new password : </label></div > 
            <div><input id="password" onChange={(e)=>inputChangeHandler(setPassword, e)} type="password"/></div>
            <input type="submit"/>
        </form>
  </>
  );
}
