import { Link, Outlet } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";

export default function Jobs() {
  const [jobs, setjobs] = useState([]);

  const url = process.env.REACT_APP_SERVER_API_ENDPOINT;

  useEffect(() => {
   axios.get(url + "jobs").then((res) => {
      // console.log(res.data);
      setjobs(res.data);
    });
  }, []);

  return (
    <div>

      <Outlet />
    </div>
  );
}
