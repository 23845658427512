import React from "react";
import axios from "axios";
import { useState, useEffect, useContext, useCallback } from "react";
import Jobcard from "./Jobcard";
import { useNavigate } from "react-router-dom";
import { ThemeProvider, createTheme } from "@mui/material/styles";

export default function PostedJobs(props) {
  var pagecount = 1;
  let url = process.env.REACT_APP_SERVER_API_ENDPOINT;
  let limit = 20;
  const darkTheme = createTheme({
    palette: {
      mode: "dark",
    },
  });

  const navigate = useNavigate();
  const [postedjobs, setPostedjobs] = useState([]);

  function customClickEvent(e: any) {
    navigate("/jobs/" + e.id, { state: "partialjob" });
  }

  function getjobs(user) {
    // console.log(user);
    // get jobs from current connected user
    //
    // you get id from the token or current page already have it
    // then search for all posted jobs by current user
    return axios.get(url + "postedjobs" + "?user=" + user);
  }

  useEffect(async () => {
    let res = await getjobs(props.user);
    // console.log(res);
    // console.log(res.data);
    setPostedjobs((prevjobs) => [...prevjobs, ...res.data]);
  }, []);

  return postedjobs.map(function (jobs: any) {
    return (
      <ThemeProvider theme={darkTheme}>
        <Jobcard
          sx={{ "border-radius": "0px" }}
          customClickEvent={customClickEvent}
          job={jobs}
        ></Jobcard>
      </ThemeProvider>
    );
  });
}
