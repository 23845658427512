import { useState } from "react";
import { useNavigate } from 'react-router';
import Popup from 'reactjs-popup';
import 'reactjs-popup/dist/index.css';
import React from "react";
import "./Modal.scss";
import axios from "axios";

const arrowStyle = { opacity: 0,
color: '#000' }; // style for an svg element
const contentStyle = { width: "35%" };

const Popupreset = (props) => {
  
  const navigate = useNavigate();
  const url = process.env.REACT_APP_SERVER_API_ENDPOINT;
  const [isResetting, setIsResetting] = useState(false);
  const [ResetOK, setResetOK] = useState(false);
  const [email, setResetEmail] = useState("");
  const [error, setError] = useState("")

  let handleSubmitReset = (e) => {
    // console.log(e);
    e.stopPropagation();
    e.preventDefault();
    // console.log(email);
    setIsResetting(true);

    
    axios.patch( url + "forgot-password" , {
        username: email,
    }
    // , { withCredentials: true }
    ).then(async response => {
      setError("")
      // console.log(response);
      // console.log(response.statusText)
      setIsResetting(false)
      // console.log(response.statusText)
      if (response.statusText !== "OK") {
        // console.log(response.status);
        if (response.status === 400) {
          setError("Please fill all the fields correctly!")
        } else if (response.status === 404) {
          setError("Invalid email")
        } else {
          setError("Something went wrong, try later ! ")
        }
      } else {
        // check your emails to reset password
        // console.log("check email");
        setResetOK(true);
        {/* const data = response.data */}
      }
    })
    .catch(error => {
      console.log(error);
      // console.log(error.response.status)
      // console.log(error.response)

      if (error.response.status === 400) {
        setError("Please fill all the fields correctly!")
      } else if (error.response.status === 404) {
        setError("Invalid email")
      } else {
      setError("Something went wrong, try later please or contact support!")
      }
      setIsResetting(false)
    })
    
  };

  return (
    <Popup trigger={<a onClick={() => {console.log("click")} } style={{ "cursor": "pointer", 
"marginTop":"10px", "font-size":"12px"   }}>
                Forgot Password </a>}
   {...{ contentStyle }}
              position="right center" modal
              >
      {closepopup => (
          <section className="modal-main" style={{"width":"40%"}}>

                  <form autoComplete="off" className="login-form" onSubmit={handleSubmitReset}>
                      <div className="control">
                      <h1>
                        Reset password
                      </h1>
                    </div>
                    <div className="control block-cube block-input">
                      <input name="username"
                        placeholder="Email"
                        type="text"
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                        required
                        onChange={(e) => setResetEmail(e.target.value)}
                        autoComplete="off"
                      />
                    <div className="bg-top">
                        <div className="bg-inner"></div>
                      </div>
                      <div className="bg-right">
                        <div className="bg-inner"></div>
                      </div>
                      <div className="bg">
                        <div className="bg-inner"></div>
                      </div>
                    </div>

                    <button className="btn block-cube block-cube-hover" type="submit">
                      <div className="bg-top">
                        <div className="bg-inner"></div>
                      </div>
                      <div className="bg-right">
                        <div className="bg-inner"></div>
                      </div>
                      <div className="bg">
                        <div className="bg-inner"></div>
                      </div>

                      { ResetOK ? <div className="text" disabled>Check your email for the link to reset</div> : !isResetting ? <div className="text"> Reset Password </div> : <div className="text" disabled > Reset Password...</div>  }
                    </button>
                  </form>

                  <div style={{ "margin":"10px" }}>{error ? error : null }
                </div>
                  <button onClick={closepopup} className="closebutton" name="close">
                    X
                  </button>
              </section>
              )}
              </Popup>

  );
};
export default Popupreset;
