import React, { useEffect, useState } from 'react';

const Nothing = () => {
  const [pageContent, setPageContent] = useState('');

  const url = process.env.REACT_APP_SERVER_API_ENDPOINT;

  useEffect(() => {
    document.title = "404 Not Found";
    if (typeof window !== "undefined") {
      fetch(url + "404", {
        method: "GET",
      //  mode: "cors",
        credentials: "include",
        headers: { "Content-Type": "application/json" },
      } )
        .then(response => response.text())
        .then(data => setPageContent("404 Page Not Found !!"))
        .catch(error => console.error('Error fetching 404 page:', error));
    }
  }, []);

  return (
    <div style={{ textAlign: 'center', marginTop: '50px' }} dangerouslySetInnerHTML={{ __html: pageContent }}>
    </div>
  );
};

export default Nothing;
