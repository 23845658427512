import Card from "@mui/material/Card";
import CardContent from "@mui/material/CardContent";
import CardMedia from "@mui/material/CardMedia";
import Typography from "@mui/material/Typography";
import { ThemeProvider, createTheme, Input, Button, CardActionArea, CardActions } from "@mui/material";
//import briefcase from "../static/images/cards/brief.png"; // relative path to image
import thunder from "../static/images/cards/thunder.svg";


function Jobcard(props: any) {

  //console.log(props);

  const theme = createTheme({
  palette: {
    mode: 'dark',
  },
  typography: {
    title: {
      fontSize: 20,
      'font-family': 'Cyber',
      
    },
    body: {
      fontSize: 14,
      'font-family': 'Cyber',
    },
    button: {
      fontStyle: 'italic',
    },
  },
  });

  function titleCase(string){
    if(string){
      return string[0].toUpperCase() + string.slice(1).toLowerCase();}
    else{
      return string;
    }
  }

  return (
<ThemeProvider theme={theme}>
  <Card
                sx={{ "margin": "15px", "font-family":"Hack" }} onClick={() => props.customClickEvent(props.job)}>
              
                <CardActionArea>
                  <CardContent sx={{ display: "flex", "align-items": "right" }}>
                    <div>
                      <CardMedia
                        component="img"
                        sx={{ width: 100, "padding-right": 20, "padding-top":20 }}
                        image={thunder}
                        alt="Live from space album cover"
                      />
                    </div>
                    <div style={{'display': 'flex', 'flex-direction': 'column'}}>
                    <div>
                      <Typography
                        sx={{ "margin-top": "10px", "text-align": "left" }}
                        gutterBottom
                        variant="title"
                        component="div"
                        
                      >
                        {titleCase(props.job.hiringorganization)}
                      </Typography>
                      <div className="jobtext">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          display="inline"
                          height="24"
                          fill="grey"
                          viewBox="0 0 24 24"
                          width="24"
                          margin-right="5"
                        >
                          <path
                            d="M0 0h24v24H0zm10 5h4v2h-4zm0 0h4v2h-4z"
                            fill="none"
                          />
                          <path d="M10 16v-1H3.01L3 19c0 1.11.89 2 2 2h14c1.11 0 2-.89 2-2v-4h-7v1h-4zm10-9h-4.01V5l-2-2h-4l-2 2v2H4c-1.1 0-2 .9-2 2v3c0 1.11.89 2 2 2h6v-2h4v2h6c1.1 0 2-.9 2-2V9c0-1.1-.9-2-2-2zm-6 0h-4V5h4v2z" />
                        </svg>
                        <Typography
                          sx={{ display: "inline", "text-align": "left" }}
                          variant="body"
                          color="text.primary"
                          margin-left="5"
                        >
                          &nbsp;&nbsp;&nbsp;{props.job.name}
                        </Typography>
                      </div>
                      <div className="joblocationtext">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          display="inline"
                          enableBackground="new 0 0 24 24"
                          height="24"
                          viewBox="0 0 24 24"
                          width="24"
                          fill="grey"
                        >
                          <g>
                            <path d="M0,0h24v24H0V0z" fill="none" />
                          </g>
                          <g>
                            <path d="M12,2c-4.2,0-8,3.22-8,8.2c0,3.32,2.67,7.25,8,11.8c5.33-4.55,8-8.48,8-11.8C20,5.22,16.2,2,12,2z M12,12c-1.1,0-2-0.9-2-2 c0-1.1,0.9-2,2-2c1.1,0,2,0.9,2,2C14,11.1,13.1,12,12,12z" />
                          </g>
                        </svg>
                        <Typography
                          sx={{ display: "inline", "text-align": "left" }}
                          variant="body"
                          color="text.primary"
                        >
                         &nbsp;&nbsp;&nbsp;{titleCase(props.job.joblocation)}
                        </Typography>
                      </div>
                      <div className="dateposted" style={{'padding-top': '10px', width:'170px'}}>
                        <Typography
                          sx={{ display: "inline", "text-align": "left"  }}
                          variant="caption"
                          color="text.primary"
                        >
                          {props.job.created_at}
                        </Typography>
                      </div>
                    </div>
                      <div className="keywords-section" style={{ marginTop: '10px', display: 'flex', flexWrap: 'wrap' }}>
                      {/* <div className="keywords-section" style={{ marginTop: '10px' }}> */}
                          {props.job.keywords && props.job.keywords.map((keyword: string, index: number) => (
                            <div key={index} style={{
                              padding: '5px 10px',
                              backgroundColor: '#333',
                              borderRadius: '4px',
                              marginRight: '5px',
                              marginBottom: '5px'
                            }}>
                              {keyword}
                            </div>
                          ))}
                        </div>
                      </div>
                  </CardContent>
                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h5"
                      component="div"
                    ></Typography>
                  </CardContent>
                </CardActionArea>
              </Card>
              </ThemeProvider>
         )};

export default Jobcard;

