import { useState , useEffect, useContext } from "react";
import axios from "axios";
import React from "react";
import "./Modal.scss";
import { useNavigate } from 'react-router';
import { UserContext } from "../context/UserContext"
import Popupreset from "./Popupreset";

const Modal = (props) => {
  const { show, close } = props;


  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPass] = useState("");
  const [message, setMessage] = useState("");

  const [userContext, setUserContext] = useContext(UserContext)

  const url = process.env.REACT_APP_SERVER_API_ENDPOINT;

  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState("")


  {/* useEffect(() =>  console.log(UserContext) ) */}
  const genericErrorMessage = "Something went wrong! Please try again later."
  let handleSubmit = (e) => {
    e.preventDefault();
    // console.log(email);
    setIsSubmitting(true);

    
      axios.post( url + "login" , {
          email: email,
          password: password,
        }, { withCredentials: true }
      ).then(async response => {
        // console.log(response);
        // console.log(response.status)
        setIsSubmitting(false)
        if (response.status !== 200) {
          // console.log("not logged in" )
          if (response.status === 400) {
            setError("Please fill all the fields correctly!")
          } else if (response.status === 401) {
            setError("Invalid email and password combination.")
          } else {
            // console.log(response);
            setError(genericErrorMessage)
          }
        } else {
          const data = response.data
          // console.log("data after login", data)
          // console.log("setcontext and local storage Token ");
          // console.log("data.token", data.token);

          window.localStorage.setItem("token", data.token);
          window.localStorage.setItem("id", data.id);
          window.localStorage.setItem("name", data.name);
          window.localStorage.setItem("type", data.type);
          window.localStorage.setItem("username", data.username);
          setUserContext(oldValues => {
            return { ...oldValues, token: data.token, details: {id : data.id, type : data.type, name : data.name, username: data.username} }
          })
          if(props.nav == "profile"){
            navigate("/profile");}else if(props.nav == "postajob"){
            navigate("/postajob");
            }
        }
      })
      .catch(error => {
            // console.log("her1");
            // console.log(error);
        setIsSubmitting(false)
        setError(genericErrorMessage)
      })
  };


  // Return null if false
  if (!show) {
    // console.log("not showing modal");
    return null;
  }


  return (
    <>

      <div>
        <section className="modal-main">
          {/* <div className="login"> */}
          {/*   <div className="form"> */}
                  <form autoComplete="off" className="login-form" onSubmit={handleSubmit}>
                      <div className="control">
                      <h1>
                        Sign In
                      </h1>
                    </div>
                    <div className="control block-cube block-input">
                      <input name="username"
                        placeholder="Email"
                        type="text"
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                        required
                        onChange={(e) => setEmail(e.target.value)}
                        autoComplete="off" 
                      />  
                    <div className="bg-top">
                        <div className="bg-inner"></div>
                      </div>
                      <div className="bg-right">
                        <div className="bg-inner"></div>
                      </div>
                      <div className="bg">
                        <div className="bg-inner"></div>
                      </div>
                    </div>
                    <div className="control block-cube block-input">
                      <input name="password" placeholder="Password" type="password"
                        required
                        onChange={(e) => setPass(e.target.value)}
                        autoComplete="off" 
                      /> 
                      <div className="bg-top">
                        <div className="bg-inner"></div>
                      </div>
                      <div className="bg-right">
                        <div className="bg-inner"></div>
                      </div>
                      <div className="bg">
                        <div className="bg-inner"></div>
                      </div>
                    </div>
                    <button className="btn block-cube block-cube-hover" type="submit">
                      <div className="bg-top">
                        <div className="bg-inner"></div>
                      </div>
                      <div className="bg-right">
                        <div className="bg-inner"></div>
                      </div>
                      <div className="bg">
                        <div className="bg-inner"></div>
                      </div>
                  {!isSubmitting ? <div className="text">
                        Log In
                      </div> : <div disabled >login...</div>}
                    </button>

                {error && <div className="alert alert-warning"> {error} </div>}
                {/* {!isSubmitting ? <button type="submit">login</button> : <button disabled type="submit">login...</button>} */}
            <Popupreset></Popupreset> 
              </form>



          <button onClick={close} className="closebutton" name="close">
            X
          </button>
        </section>
      </div>
    </>

  );
};
export default Modal;
