import * as React from "react";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useEffect, useState, useContext } from "react";
import "./responsivebar.css";
import Metamask from "./Metamask";
import { Web3ReactProvider } from "@web3-react/core";
import Web3 from "web3";
import Modal from "./Modal";
import Register from "./Register";
import { UserContext } from "../context/UserContext";
import { Link } from "react-router-dom"; // Import Link



import { WagmiConfig } from "wagmi";
import { ConnectKitProvider, ConnectKitButton, getDefaultClient } from "connectkit";
import Customwalletbutton from "./Customwalletbutton";


function getLibrary(provider: any) {
  return new Web3(provider);
}


const ResponsiveAppBar = (props) => {
  const navigate = useNavigate();
  const [userContext, setUserContext, isSigningOut, setIsSigningOut] = useContext(UserContext);
  const [showModal, setShowModal] = useState(false);
  const [showRegister, setShowRegister] = useState(false);

  const client = props.client;

  function navtohome() {
    // console.log("nav");
    navigate("/");
  }

  function navtoblog() {
    navigate("/blog");
  }
  
  function navtoprofile() {
    // console.log("profile");
    navigate("/profile");
  }

  function signout() {
    // send req to signout user
    setIsSigningOut(true);

    // console.log("sign out click");
    // console.log(userContext.token, "used to signout user");
    let token = window.localStorage.getItem("token");
    // console.log(window.localStorage.getItem("token"));

    fetch(process.env.REACT_APP_SERVER_API_ENDPOINT + "logout", {
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then(async (response) => {
        // console.log(response);
        if (response.ok) {
          // console.log("remove token and values from local storage and context");
          setUserContext((oldValues) => {
            return { ...oldValues, details: undefined, token: null };
          });
          
          window.localStorage.removeItem("token");
          window.localStorage.removeItem("id");
          window.localStorage.removeItem("username");
          window.localStorage.removeItem("name");
          window.localStorage.removeItem("type");
          window.localStorage.setItem("logout", Date.now());
          navigate("/");
          setIsSigningOut(false);

        }
      })
      .catch((error) => {
        console.log(error);
      })
    // you need to remove refreshToken here as well, here is reemoved in backend
  }

  const handleToggleRegister = () => {
    setShowRegister(!showRegister);
  };

  const handleToggleModal = () => {
    setShowModal(!showModal);
  };

  useEffect(() => {
    // console.log(props.signinstate);
    if (props.signinstate) {
      setShowModal(true);
    }

    if (window.localStorage.getItem("token")) { 
    // if user is connected 
    //then get user details from localstorage to store them in Context in case of page refresh ( Context state gets lost ) 
      // console.log(typeof window.localStorage.getItem("token"));
      // console.log(window.localStorage.getItem("token"));
      // console.log(window.localStorage.getItem("id"));
      setUserContext((oldValues) => {  
        if( window.localStorage.getItem("token") == "null" ) {
          // here localstorage store null as string which need to be represented as null for conditional rendering 
          // console.log("replenish localstorage")
        return {
          ...oldValues,
          details: null,
          token: null,
        };}else{
        return {
          ...oldValues,
          details: { id: window.localStorage.getItem("id"),  type: window.localStorage.getItem("type"), name: window.localStorage.getItem("name"), username: window.localStorage.getItem("username") },
          token: window.localStorage.getItem("token"),
        }
        }
      });
    }
  }, []);

  return (
    <>
     {/* {console.log(client)} */}
    <WagmiConfig client={client}>
      <ConnectKitProvider theme="default" mode="dark" options={{
          embedGoogleFonts: true,
        }}>
      <div className="menu" id="myTopnav">
        <div>
          <a  onClick={navtohome} className="active">
            Home
          </a>
          <a  onClick={navtoblog} className="active">
            Blog
          </a>
          {/* <a onClick={navtohome}>Jobs</a> */}

          {userContext.token ? <a onClick={navtoprofile}>Profile</a>     
           : null}
          <a>
            {/* <Web3ReactProvider getLibrary={getLibrary}> */}
            {/*   <Metamask /> */}
            {/* </Web3ReactProvider> */}
            {/* <ConnectKitButton /> */}
            <Customwalletbutton />
          </a>
          {userContext.token ? (
            <a onClick={signout}>Sign Out</a>
          ) : (
            <a onClick={handleToggleModal}>Sign In</a>
          )}

          {userContext.token ? null : (
            <a onClick={handleToggleRegister}>Register</a>
          )}
        </div>
      </div>
      <div>
        {!userContext.token ? (
          <Modal nav={props.nav} show={showModal} close={handleToggleModal}>
            <p>Modal</p>
          </Modal>
        ) : null}
        <Register show={showRegister} close={handleToggleRegister}>
          <p>Register</p>
        </Register>
      </div>
       </ConnectKitProvider>
    </WagmiConfig>
    </>
  );
};
export default ResponsiveAppBar;
