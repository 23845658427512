import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter,
  Routes,
  Route, } from "react-router-dom";
import Postajob from "./routes/postajob";
import Editajob from "./routes/editajob";
import Job from "./routes/job";
import Jobs from "./routes/jobs";
import Profile from "./routes/profile";
import Blog from "./routes/Blog";
import ArticlePage from "./routes/ArticlePage"; // Import the ArticlePage component
import Nothing from "./routes/Nothing";

import ResetPassword from "./routes/resetpassword";
import Sitemap from "./routes/sitemap";
import { UserProvider } from "./context/UserContext";

import { createClient, chain } from "wagmi";
import { getDefaultClient } from "connectkit";




//web3 config 
//const alchemyId = process.env.ALCHEMY_ID;
const alchemyId = "Kh080DdCGr-buCECtYpwKpHL8LdRe_E7";
//const chains = [chain.mainnet,  chain.polygon, chain.optimism, chain.arbitrum];
const chains = [chain.goerli];

// console.log({alchemyId});

const client = createClient(
  getDefaultClient({
    appName: "HashWork",
    alchemyId,
    chains,
  }),
);





ReactDOM.render(
  <React.StrictMode>
  <UserProvider>
  <BrowserRouter>
    <Routes>
      <Route path="/" element={<App client={client}/>} />
      <Route path="postajob" element={<Postajob client={client}/>} />
      <Route path="editajob" >
        <Route path=":jobId" element={<Editajob client={client}/>} />
      </Route> 
      <Route path="jobs" element={<Jobs />} >
        <Route path=":jobId/:jobTitle" element={<Job client={client} />} />
      </Route>
      <Route path="profile" element={<Profile client={client}/>} />
      <Route path="reset-password/:token" element={<ResetPassword client={client}/>} />
      <Route path="sitemap.xml" element={<Sitemap />} />
      <Route path="blog" element={<Blog />} />
      <Route path="/articles/:articleId/:slug" element={<ArticlePage />} />
      {/* <Route
      path="*"
      element={
        <main style={{ padding: "1rem" }}>
          <p>There's nothing here!</p>
        </main>
      }
    /> */}
     <Route
      path="*"
      element={<Nothing/>}
    />
    </Routes>
  </BrowserRouter>
  </UserProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

