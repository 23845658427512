import React, { useState, useEffect } from "react";
import axios from "axios";

export default function Sitemap() {
  const [sitemap, setSitemap] = useState("");

  const url = process.env.REACT_APP_SERVER_API_ENDPOINT;

  useEffect(() => {
    console.log(url + "sitemap.xml");
    axios.get(url + "sitemap.xml", {
      headers: {
        'Content-Type': 'application/xml; charset=utf-8'
      }
    }).then((res) => {
      setSitemap(res.data);
    });
  }, []);

  return (
    <div>
      <pre>{sitemap}</pre>
    </div>
  );
}

