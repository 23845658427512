import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useParams } from 'react-router-dom';
import { Helmet } from 'react-helmet'; // Import Helmet for SEO
import './ArticlePage.css'; // Added import for ArticlePage CSS
import { Link } from 'react-router-dom'; // Import Link from react-router-dom

export default function ArticlePage() {
  const [article, setArticle] = useState(null);
  const { articleId } = useParams();
  const url = process.env.REACT_APP_SERVER_API_ENDPOINT;

  useEffect(() => {
    console.log("articleID", articleId)

    axios.get(`${url}articles/${articleId}`)
      .then(response => {
        setArticle(response.data);
      })
      .catch(error => {
        console.error('Error fetching article:', error);
      });
  }, [articleId]);

  if (!article) {
    return (
      <div className="article-loading">Loading...</div>
    );
  }

  // console.log(article.content)
  // console.log(article.title)
  console.log( article.content.split(" ").length)
  const article_summary = article.content.split(" ").length > 60 ? article.content.split(" ").slice(0, 60).join(" ") : article.content;
  console.log(article_summary)
  // console.log(article_summary)

  return (
    <>
      <Helmet>
        <title>{article.title}</title>
        <meta name="description" content={article_summary} />
        {/* <meta name="keywords" content={article.keywords.join(', ')} /> */}
        <meta property="og:title" content={article.title} />
        <meta property="og:description" content={article_summary} />
        <meta property="og:type" content="article" />
        <meta property="og:url" content={window.location.href} />
        <meta property="og:image" content={article.image} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={article.title} />
        <meta name="twitter:description" content={article_summary} />
        <meta name="twitter:image" content={article.image} />
      </Helmet>
      <div className="home-icon-container">
          <Link to="/blog" className="home-icon-link">
            <img src="/icons8-exit-64.png" alt="Home" className="home-icon" />
          </Link>
        </div>
        <button className="loadmorebutton">
          <Link to="/" className="findlink">Find Jobs</Link>
        </button>
        
      <div className="article-container">
        <h1 className="article-title">{article.title}</h1>
        <div className="article-content" dangerouslySetInnerHTML={{ __html: article.content }}></div>
      </div>
    </>
  );
}