import { Link, Outlet } from "react-router-dom";
import { useState, useEffect, useCallback, useContext } from "react";
import ResponsiveAppBar from "../components/ResponsiveAppBar";
import { UserContext } from "../context/UserContext"
import Loader from "../components/Loader";
import PostedJobs from "../components/PostedJobs";
import "./profile.css";

export default function Profile(props) {
  const [profile, setprofile] = useState([]);
  const [userContext, setUserContext, isSigningOut, setIsSigningOut] = useContext(UserContext);
  const [conn, setconn] = useState(false);

  // New states for edit mode and editable fields
  const [editMode, setEditMode] = useState(false);
  const [userName, setUserName] = useState(userContext.details?.name || '');
  const [userType, setUserType] = useState(userContext.details?.type || 'Recruiter');
  // const [isSigningOut, setIsSigningOut] = useState(false);

  // get token into context from local storage 
  //
  let token = window.localStorage.getItem('token');



  const fetchUserDetails = useCallback(() => {
      {/* console.log("fetch"); */}
    fetch(process.env.REACT_APP_SERVER_API_ENDPOINT + "me", {
      method: "GET",
      credentials: "include",
      // Pass authentication token as bearer token in header
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${userContext.token} `,
     },
    }).then(async response => {
      // console.log(response);
      if (response.ok) {
        {/* console.log("ok"); */}
        const data = await response.json()
        // console.log("data", data);
        // console.log("data.user.id", data.user.id);
        // console.log("data.user.username", data.user.username);
        // console.log("data.user.type", data.user.type);
        // console.log("data.user.name", data.user.name);
        setconn(true);
        setUserContext(oldValues => {
          return { ...oldValues, details: {id: data.user.id, username: data.user.username, type : data.user.type, name: data.user.name} }
        })
        window.localStorage.setItem("id", data.user.id);
        window.localStorage.setItem("username", data.user.username);
        window.localStorage.setItem("type", data.user.type);
        window.localStorage.setItem("name", data.user.name);
      } else {
          {/* console.log("her1e"); */}
        if (response.status === 401) {
          // Edge case: when the token has expired.
          // This could happen if the refreshToken calls have failed due to network error or
          // User has had the tab open from previous day and tries to click on the Fetch button
          {/* window.location.reload() // can be too much should be stopped after x requests */} 
          //not connected
          {/* setconn(false) */}

          setUserContext(oldValues => {
            return { ...oldValues, details: null }
          })

        window.localStorage.setItem("id", null);
        window.localStorage.setItem("username", null);
        window.localStorage.setItem("type", null);
        window.localStorage.setItem("name", null);
        } else {
          // console.log("here");
          setconn(false)
          setUserContext(oldValues => {
            return { ...oldValues, details: null }
          })
        window.localStorage.setItem("id", null);
        window.localStorage.setItem("username", null);
        window.localStorage.setItem("type", null);
        window.localStorage.setItem("name", null);
        }
      }
    })
  }, [setUserContext, userContext.token])

  const updateUserDetails = async () => {
    try {
      // update the user details on the server. Adjust the API endpoint and method as needed.
      console.log(userContext.details.id, userName, userType)
      const response = await fetch(process.env.REACT_APP_SERVER_API_ENDPOINT + "users/" + userContext.details.id, {
      method: "PUT",
      credentials: "include",
      headers: {
        "Content-Type": "application/json",
          Authorization: `Bearer ${userContext.token} `,
        },
        body: JSON.stringify({ "id": userContext.details.id, "name": userName, "type": userType })
      });

      // console.log('upadte response', response)
      // const data = await response.json();
      if (response.ok) {
        // update userContext and possibly other states if necessary
        // setUserName(userName)
        // console.log(userName, userType)
        // setUserType(userType)
        window.localStorage.setItem("type", userType);
        window.localStorage.setItem("name", userName);
        setUserContext(oldValues => {
          return {
            ...oldValues,
            details: {
              ...oldValues.details,
              name: userName,
              type: userType
            }
          };
        });
        setEditMode(false); // exit edit mode
      } else {
        // handle errors
      }
    } catch (error) {
      console.error("Error updating user details:", error);
    }
  };


  useEffect(() => {
    // fetch only when user details are not present
    
    // console.log("useeffect", userContext.details, !userContext.details); 
    // console.log("isSigningOut", isSigningOut);
    if (!userContext.details && !isSigningOut) {
    //  console.log("fetch user")
     userContext.token = token ;
     {/* console.log(userContext.token); */}
     fetchUserDetails()
    }
  }, [userContext.details, fetchUserDetails, isSigningOut])


  return userContext.details === null ? (
    "Error Loading Profile"
  ) : !userContext.details ?(
    <Loader />
  ) : (
    <>
   <ResponsiveAppBar client={props.client}/>
   <div className="userprofile1" >
   <div style={{"font-size":"20px"}} >
      User Profile 
    </div>
      <br></br>
      <div style={{"border-style":"solid", "border-width": "2px", "border-color": "rgb(215,251,0)", "position": "relative"}} >

      {editMode ? (
          <>

            <p style={{color: "rgb(215,251,0)", "margin-left": "8px"}}>  User email : </p> 
              <p style={{"margin-left": "12px"}}> 
              {userContext.details.username}
            </p>
            <p style={{color: "rgb(215,251,0)", "margin-left": "8px"}}>  Type : </p> 
            <p style={{"margin-left": "12px"}}> 
            <select value={userType} onChange={e => setUserType(e.target.value)}>
              <option value="Recruiter">Recruiter</option>
              <option value="Job Applicant">Job Applicant</option> {/* Example values */}
            </select>
            </p>
            <p style={{color: "rgb(215,251,0)", "margin-left": "8px"}}>  User Name : </p> 
          <p style={{"margin-left": "12px"}}> 
          {/* {userContext.details.type} */}
            <input value={userName} onChange={e => setUserName(e.target.value)} />
          </p>
            <button className="buttonedit" onClick={updateUserDetails}>Save</button>
          </>
        ) : (
          <>
        <p style={{color: "rgb(215,251,0)", "margin-left": "8px"}}>  User email : </p> 
          <p style={{"margin-left": "12px"}}> 
          {userContext.details.username}
        </p>
        <p style={{color: "rgb(215,251,0)", "margin-left": "8px"}}>  Type : </p> 
          <p style={{"margin-left": "12px"}}> 
          {userContext.details.type}
          {/* Recruiter */}
        </p>
        <p style={{color: "rgb(215,251,0)", "margin-left": "8px"}}>  User Name : </p> 
          <p style={{"margin-left": "12px"}}> 
          {userContext.details.name}
          {/* Anas Boukharta  */}
        </p>
        {/* <p style={{color: "rgb(215,251,0)", "margin-left": "8px"}}> Posted Jobs : </p>  */}
          {/* <p style={{"margin-left": "12px"}}>  */}
          {/* {userContext.details.postedjobs} */}
          {/* 0 */}
        {/* </p> */}
        <button className="buttonedit" onClick={() => setEditMode(true)}>Edit</button>
        </>
        )}
      </div> 
    <h1>
    Your Posted Jobs : 
    </h1>
    <PostedJobs user={userContext.details.id}></PostedJobs > 
    {/* <PostedJobs ></PostedJobs > */} 
    </div>
  </>
  );
}
