import react from "react";
import { useCallback, useRef, useState, useEffect } from "react";
import axios from "axios";


// Code does not show last rows in load more, not really problematic when we have a lot of rows which will be the case in our app , debugging not done yet 

var pagecount = 1;
let url = process.env.REACT_APP_SERVER_API_ENDPOINT
let limit = 10;
function getjobs ({ pagecount , limit }) { 
  {/* console.log(url + "jobspage" + "?limit=" + limit + "&page=" + pagecount); */} 
  return axios.get(url + "jobspage" + "?limit=" + limit + "&page=" + pagecount);    }


export function useInfiniteLoading(setIsLoading) {
  const initialPageLoaded = useRef(false);
  const [hasMore, setHasMore] = useState(true);
  const [jobsi, setjobsi] = useState([]);

  const loadjobs = async (init_) => {
    setIsLoading(true);
    let data = await getjobs({
      pagecount, limit
    }).then((res) => {
      
      {/* console.log(pagecount); */}
      if(initialPageLoaded.current == true){
      pagecount++;}
      return res.data;})
      // you should add here a check to see if full count is embedded in data[Ø]
      // without triggering undefined Error 
      setHasMore(data[0]?.full_count/limit > pagecount);
      setjobsi((prevjobs) => [...prevjobs, ...data]);
      setIsLoading(false);

  };

  useEffect(() => {
    if (initialPageLoaded.current) {
      return;
    }
    pagecount = 1;
    {/* console.log("here"); */}
    loadjobs(); 
    pagecount = 2;

    setTimeout(function(){initialPageLoaded.current = true}, 1500);
  }, [getjobs]);

  {/* console.log(jobsi); */}

  return {jobsi,hasMore,loadjobs};
};
