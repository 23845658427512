import ReactDOM from "react-dom";
import React from "react";
import { useParams } from "react-router-dom";
import { useState, useEffect } from "react";
import axios from "axios";
import { Outlet, useNavigate } from "react-router-dom";
import { useTheme, ThemeProvider, createTheme } from "@mui/material/styles";
import { Helmet } from "react-helmet";
import "./Routes.css";
import "./job.css";
import "./cyberbutton.css";
import ResponsiveAppBar from "../components/ResponsiveAppBar";
import LoadingSpinner from "../components/Loadingspinner";

let backendurl = process.env.REACT_APP_SERVER_API_ENDPOINT;


export default function Job(props) {
  const [job, setjob] = useState([]);
  const [editable, setEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useNavigate();

  let params = useParams();

  useEffect(() => {
    axios
      .get(backendurl + "jobs/" + parseInt(params.jobId))
      .then((res: any) => {
        console.log(res.data);
        setjob(res.data[0]);
        setIsLoading(false); // Add this line

      });

    axios
      .get(backendurl + "jobuserid/" + parseInt(params.jobId))
      .then((res: any) => {
        // console.log("jobuserid",res.data);
        // console.log(window.localStorage.getItem("id"));
        // console.log(window.localStorage);
        if (res.data[0].users_id === null) {
          setEdit(false);
        } else if (res.data[0].users_id == window.localStorage.getItem("id")) {
            setEdit(true);
        } else {
            setEdit(false);
        }
      });

  }, []);

  function navigatetohome() {
    navigate("/", { state: "restartsearch" });
  }

  return (
    <>
      <Helmet>
        <title>{job.name}</title>
        <meta name="description" content={job.description} />
        <meta name="keywords" content={job.name + ", Crypto Jobs, Blockchain, Remote Jobs, Tech Jobs, Programming Jobs, Eksodos"} /> 
        <meta property="og:title" content={job.name + " - Eksodos"} />
        <meta property="og:description" content={job.description} />
        <meta property="og:image" content="URL_TO_IMAGE" />
        <meta property="og:url" content={"https://www.eksodos.com/jobs/" + job.id} />
        <meta name="twitter:card" content="summary_large_image" /> 
      </Helmet>
      <ResponsiveAppBar client={props.client} />
      <br></br>
      {isLoading ? <LoadingSpinner /> : (

      <div className="mainjob">
        {/* <div className="logoVcrypto" onClick={navigatetohome}> */}
        {/*   PAID IN CRYPTO */}
        {/* </div> */}
        <div className="parent">
          <div className="div1">
            <h1 className="p1">{job.name}</h1>
          </div>
          <div className="div2" dangerouslySetInnerHTML={{ __html: job.description }}></div>
          <div className="div3">
            <b> Salary:</b> {job.salary} {job.salarycurrency}
          </div>
            <div className="div4">
              <b> Location:</b> {job.joblocation}
            </div>
          <div className="div5">
            <button onClick={ () => window.location.href = job.url} class="cybr-btn">
              Apply<span aria-hidden>_</span>
              <span aria-hidden class="cybr-btn__glitch">
                Apply_
              </span>
              <span aria-hidden class="cybr-btn__tag">
                ETH
              </span>
            </button>
            { editable ? 
              <button onClick={() => navigate('/editajob/' + job.id)} class="cybr-btn">
              Edit<span aria-hidden>_</span>
                <span  aria-hidden class="cybr-btn__glitch">
                Edit_
              </span>
              <span aria-hidden class="cybr-btn__tag">
                ETH
              </span>
            </button> : null }
          </div>
        </div>
        <div className="maintext" style={{ padding: "1rem" }}>
          <h1></h1>
          <h2></h2>
          <p></p>
          <p></p>
          <p></p>
        </div>
      </div>
          )}

    </>
  );
}
``

