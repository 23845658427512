import { useState , useContext } from "react";
import axios from "axios";
import React from "react";
import "./Modal.css";
import { useNavigate } from 'react-router';
import { UserContext } from "../context/UserContext";

const Register = (props) => {
  const { show, close } = props;


  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPass] = useState("");
  const [passwordconf, setPassConf] = useState("");
  const [message, setMessage] = useState("");
  
  const [userContext, setUserContext] = useContext(UserContext)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [error, setError] = useState("")

  const url = process.env.REACT_APP_SERVER_API_ENDPOINT;
  const genericErrorMessage = "Something went wrong! Please try again later."

  //setError(""); 

  let handleSubmit = async (e) => {
    e.preventDefault();
    // console.log(email);

      axios.post( url + "register" , {
          email: email,
          password: password,
          passConf: password,  // add this field in UI 
        }, { withCredentials: true }
      ).then(async response => {
        setIsSubmitting(false)
        // console.log("response",response);

          window.localStorage.setItem("token", response.data.tok);
          setUserContext(oldValues => {
            return { ...oldValues, token: response.data.tok }
          })
          // set modal off ==> send confirmation email 
          close();
      })
      .catch(error => {
        console.log(error);
        // console.log(error.response);
        {/* console.log(response.json()); */}
        {/* console.log(response.json().message); */}
        setIsSubmitting(false)
        if(error.response.status == 404){
          setError(genericErrorMessage)}
          else{
        try{
          setError(error.response.data)}catch(error){
            setError(genericErrorMessage)};
          }
      })
  };
  // Return null if false
  if (!show) {
    return null;
  }

  return (
    <>
      <div>

        <section className="modal-main">
                  <form autoComplete="off" className="login-form" onSubmit={handleSubmit}>
                      <div className="control">
                      <h1>
                      Register
                      </h1>
                    </div>
                    <div className="control block-cube block-input">
                      <input name="username"
                        placeholder="Email"
                        type="text"
                        pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
                        required
                        onChange={(e) => setEmail(e.target.value)}
                        autoComplete="off" 
                      />  
                    <div className="bg-top">
                        <div className="bg-inner"></div>
                      </div>
                      <div className="bg-right">
                        <div className="bg-inner"></div>
                      </div>
                      <div className="bg">
                        <div className="bg-inner"></div>
                      </div>
                    </div>

                    <div className="control block-cube block-input">
                      <input name="password" placeholder="Password" type="password"
                        required
                        onChange={(e) => setPass(e.target.value)}
                        autoComplete="off" 
                      /> 
                      <div className="bg-top">
                        <div className="bg-inner"></div>
                      </div>
                      <div className="bg-right">
                        <div className="bg-inner"></div>
                      </div>
                      <div className="bg">
                        <div className="bg-inner"></div>
                      </div>
                    </div>

                    <div className="control block-cube block-input">
                      <input name="password" placeholder="Password Confirmation" type="password"
                        required
                        onChange={(e) => setPassConf(e.target.value)}
                        autoComplete="off" 
                      /> 
                      <div className="bg-top">
                        <div className="bg-inner"></div>
                      </div>
                      <div className="bg-right">
                        <div className="bg-inner"></div>
                      </div>
                      <div className="bg">
                        <div className="bg-inner"></div>
                      </div>
                    </div>

                    <button className="btn block-cube block-cube-hover" type="submit">
                      <div className="bg-top">
                        <div className="bg-inner"></div>
                      </div>
                      <div className="bg-right">
                        <div className="bg-inner"></div>
                      </div>
                      <div className="bg">
                        <div className="bg-inner"></div>
                      </div>
                  {!isSubmitting ? <div className="text">
                        Register
                      </div> : <div className="text" disabled >registering...</div>}
                    </button>
                    <div>
                      <br></br> 
                      {error && <div > {error} </div>}</div>
                  </form>

          <button onClick={close} className="closebutton" name="close">
            X
          </button>

        </section>
      </div>
    </>
  );
};
export default Register;
